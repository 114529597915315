import {useTheme} from 'shared/model/hooks'
import {useDispatch} from 'react-redux'
import {IconSun, IconMoon} from 'shared/icons'
import {classNames} from 'shared/lib/utills'
import classes from './ThemeSwitch.module.scss'

export const ThemeSwitch = () => {
  const {theme, toggleTheme} = useTheme()
  const dispatch = useDispatch()

  return (
    <button
      className={classNames(classes.theme, {[classes.dark]: theme === 'dark'})}
      role='switch'
      aria-checked={theme === 'dark'}
      onClick={() => dispatch(toggleTheme())}
    >
      <span className={classes.icon}><IconSun /></span>
      <span className={classes.icon}><IconMoon /></span>
    </button>
  )
}