import {createSlice} from '@reduxjs/toolkit'

const getTheme = () => (
  localStorage.getItem('theme') ?? 'dark'
)

const initialState = {
  theme: getTheme()
}

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.theme === 'light'
        ? state.theme = 'dark'
        : state.theme = 'light'
    }
  }
})

const {reducer: themeReducer} = themeSlice
const {toggleTheme} = themeSlice.actions

export {themeReducer, toggleTheme}