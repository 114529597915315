import {Logo} from './ui/Logo'
import {ThemeSwitch} from 'features'
import classes from './Header.module.scss'

export const Header = () => {
  return (
    <header className={classes.header}>
      <div className={classes.wrapper}>
        <Logo />
        <div className={classes.body}>
          <span>тема:</span>
          <ThemeSwitch />
        </div>
      </div>
    </header>
  )
}