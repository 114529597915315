import {useSelector} from 'react-redux'
import {setType, setVolume, setCoast} from '../reducers/_calcSlice'

export const useCalc = () => {
  const {type, volume, coast} = useSelector((state) => state.calcReducer)

  return {
    type,
    setType,
    volume,
    setVolume,
    coast,
    setCoast
  }
}