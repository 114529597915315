export const PRICE = {
  fresh: {
    100: '6 000',
    200: '8 000',
    300: '9 000',
    400: '10 000',
    600: '12 000'
  },
  sea: {
    100: '7 000',
    200: '10 000',
    300: '12 000',
    400: '13 000',
    600: '15 000'
  }
}

export const TYPES = [
  {label: 'Пресный', value: 'fresh'},
  {label: 'Морской', value: 'sea'}
]

export const VOLUMES = [
  {label: 'до 200 литров', value: '100'},
  {label: 'от 200 до 300 литров', value: '200'},
  {label: 'от 300 до 400 литров', value: '300'},
  {label: 'от 400 до 600 литров', value: '400'},
  {label: 'от 600 до 800 литров', value: '600'},
]