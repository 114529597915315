export const CARDS = [
  {
    id: '1',
    title: 'Пресноводный аквариум',
    text: 'Искусственные растения и крупные цихлиды.',
    image: 'assets/video/track1/01.jpg',
    video: 'assets/video/track1/01.mp4'
  },
  {
    id: '2',
    title: 'Пресноводный аквариум',
    text: 'Аквариум с живыми растениями.',
    image: 'assets/video/track2/02.jpg',
    video: 'assets/video/track2/02.mp4'
  },
  {
    id: '3',
    title: 'Рифовый аквариум',
    text: 'Рифовый аквариум с мягкими кораллами.',
    image: 'assets/video/track3/03.jpg',
    video: 'assets/video/track3/03.mp4'
  },
  {
    id: '4',
    title: 'Морской аквариум',
    text: 'Аквариум с искусственными декорациями.',
    image: 'assets/video/track4/04.jpg',
    video: 'assets/video/track4/04.mp4'
  },
  {
    id: '5',
    title: 'Пресноводный аквариум',
    text: 'Пресноводный аквариум в стиле "ПСЕВДОМОРЕ".',
    image: 'assets/video/track5/05.jpg',
    video: 'assets/video/track5/05.mp4'
  },
  {
    id: '6',
    title: 'Пресноводный аквариум',
    text: 'Пресноводный аквариум в стиле "ПСЕВДОМОРЕ".',
    image: 'assets/video/track6/06.jpg',
    video: 'assets/video/track6/06.mp4'
  },
]