import classes from './Contact.module.scss'
import {IconWhatsapp} from 'shared/icons'
import {Modal} from 'shared/ui'
import {useCalc} from 'shared/model/hooks'
import {BASE_URL, HELLO_URL, TYPES, VOLUMES} from 'shared/config/whatsapp'

export const Contact = () => {
  const {type, volume} = useCalc()

  const modalContent = (url) => (
    <ul className={classes.list}>
      <li>
        <a href={url}>
          <span>Написать в WhatsApp</span>
        </a>
      </li>
      <li>
        <a href='tel:+79067144310'>
          <span>Позвонить +7 (906) 714-43-10</span>
        </a>
      </li>
    </ul>
  )

  const handleContactClick = () => {
    const url = [
      BASE_URL,
      HELLO_URL,
      type ? `${TYPES.title}${TYPES[type]}` : '',
      volume ? `${VOLUMES.title}${VOLUMES[volume]}` : ''
    ].join('')

    Modal.info({
      getContainer: () => document.querySelector('#app'),
      centered: true,
      closable: true,
      icon: null,
      maskClosable: true,
      content: modalContent(url)
    })
  }

  return (
    <button className={classes.btn}
      onClick={handleContactClick}
      type='button'
    >
      <IconWhatsapp />
      <span>Связаться</span>
    </button>
  )
}