import {
  combineReducers,
  configureStore
} from '@reduxjs/toolkit'

import {
  themeReducer,
  calcReducer
} from 'shared/model/reducers'

const rootReducer = combineReducers({
  themeReducer,
  calcReducer
})

export const store = configureStore({
  reducer: rootReducer
})