// https://ant.design/components/select/
import {Select as Exrernal} from 'antd'
import {classNames} from 'shared/lib/utills'
import classes from './Select.module.scss'

export const Select = ({
  className,
  options,
  placeholder,
  disabled,
  value,
  onFocus,
  onChange,
  onBlur
}) => {
  return (
    <Exrernal
      className={classNames(classes.select, {}, [className])}
      getPopupContainer={(trigger) => trigger.parentElement}
      options={options}
      placeholder={placeholder}
      disabled={disabled}
      value={value}
      onFocus={onFocus}
      onChange={onChange}
      onBlur={onBlur}
    />
  )
}

/*
const {Option: ExrernalOption} = ExrernalSelect
<ExrernalSelect
  className={classes.info__field}
  getPopupContainer={(trigger) => trigger.parentElement}
  options={options}
  value={division}
  onChange={(value) => setDivision(value)}
>
  <ExrernalOption key='1' value='Подразделение 1'>Подразделение 1</ExrernalOption>
  <ExrernalOption key='2' value='Подразделение 2'>Подразделение 2</ExrernalOption>
  <ExrernalOption key='3' value='Подразделение 3'>Подразделение 3</ExrernalOption>
</ExrernalSelect>
*/