import classes from './SectionGallery.module.scss'
import {VideoCard} from 'features'

export const SectionGallery = ({
  title,
  items,
}) => {
  return (
    <section className={classes.wrapper}>
      <h2 className={classes.title}>{title}</h2>
      <div className={classes.body}>
        {items?.length > 0 && items.map((item) => (
          <VideoCard
            key={item.id}
            title={item.title}
            text={item.text}
            image={item.image}
            video={item.video}
          />
        ))}
      </div>
    </section>
  )
}