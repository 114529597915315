import classes from './SectionPrimary.module.scss'
import aqua from './assets/aqua.gif'

export const SectionPrimary = ({
  title,
  text,
}) => {
  return (
    <section className={classes.wrapper}>
      <div className={classes.image}>
        <img src={aqua} alt='Аквадизайнер' />
      </div>
      <div className={classes.body}>
        <h1 className={classes.title}>{title}</h1>
        <p className={classes.text}>{text}</p>
      </div>
    </section>
  )
}