import styles from './VideoCard.module.scss'
import {Card} from 'entities/index'
import {Modal} from 'shared/ui'

export const VideoCard = ({
  title,
  text,
  image,
  video,
}) => {
  const modalContent = (
    <video className={styles.content}
      src={video}
      controls
      autoPlay
    />
  )

  const handleVideoCardClick = () => {
    Modal.info({
      getContainer: () => document.querySelector('#app'),
      centered: true,
      icon: null,
      maskClosable: true,
      content: modalContent
    })
  }

  return (
    <button type='button'
      onClick={handleVideoCardClick}
    >
      <Card
        title={title}
        text={text}
        image={image}
      />
    </button>
  )
}