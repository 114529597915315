import classes from './Card.module.scss'
import {IconPlay} from 'shared/icons'

export const Card = ({
  title,
  text,
  image,
}) => {
  return (
    <div className={classes.card}>
      <div className={classes.image}>
        <span>
          <IconPlay />
        </span>
        <img
          src={image}
          alt={title}
        />
      </div>
      <h3 className={classes.title}>
        {title}
      </h3>
      <p className={classes.text}>
        {text}
      </p>
    </div>
  )
}