import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {toggleTheme} from '../reducers/_themeSlice'

export const useTheme = () => {
  const {theme} = useSelector((state) => state.themeReducer)

  useEffect(() => {
    localStorage.setItem('theme', theme)
    document.documentElement.style.colorScheme = theme

    const $cssLight = document.querySelector('#css-light')
    const $cssDark = document.querySelector('#css-dark')

    if (!theme && !$cssLight && !$cssDark) return

    if (theme === 'dark') {
      $cssDark.disabled = false
      const timerID = setTimeout(() => $cssLight.disabled = true, 100)
      return () => clearTimeout(timerID)
    }

    if (theme === 'light') {
      $cssLight.disabled = false
      const timerID = setTimeout(() => $cssDark.disabled = true, 100)
      return () => clearTimeout(timerID)
    }
  }, [theme])

  return {theme, toggleTheme}
}