import './styles/index.scss'
import {useEffect, useState} from 'react'
import {useTheme} from 'shared/model/hooks'
import {classNames} from 'shared/lib/utills'
import {Header, SectionPrimary, SectionGallery, Footer} from 'widgets'
import {Preloader} from 'shared/ui'
import {CostCalc} from 'features'
import {CARDS} from 'shared/config/cards'

export const App = () => {
  const {theme} = useTheme()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const timerID = setTimeout(() => {
      setIsLoading(false)
    }, 1500)
    return () => clearTimeout(timerID)
  }, [])

  return (
    <div className={classNames('app', {}, [theme])} id='app'>
      {isLoading && <Preloader />}
      <Header />
      <div className='container'>
        <SectionPrimary
          title='Обслуживание аквариумов'
          text='Четыре выезда в месяц (+экстренные выезды). Расходные материалы входят в обслуживание (кроме морских и сложных вариантов с живыми растениями).'
        />
        <CostCalc />
        <SectionGallery
          title='Галерея'
          items={CARDS}
        />
      </div>
      <Footer />
    </div>
  )
}