export const BASE_URL = 'https://api.whatsapp.com/send?phone=79067144310'

export const HELLO_URL = '&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5,%20%D0%92%D0%BB%D0%B0%D0%B4%D0%B8%D0%BC%D0%B8%D1%80!%20%D0%98%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D1%8E%D1%82%20%D0%B2%D0%B0%D1%88%D0%B8%20%D1%83%D1%81%D0%BB%D1%83%D0%B3%D0%B8.%20'

export const TYPES = {
  //
  title: '%D0%A2%D0%B8%D0%BF%20%D0%B0%D0%BA%D0%B2%D0%B0%D1%80%D0%B8%D1%83%D0%BC%D0%B0%3A%20',
  // 'пресный'
  fresh: '%D0%BF%D1%80%D0%B5%D1%81%D0%BD%D1%8B%D0%B9.%20',
  // 'морской',
  sea: '%D0%BC%D0%BE%D1%80%D1%81%D0%BA%D0%BE%D0%B9.%20',
}

export const VOLUMES = {
  // Объём аквариума:
  title: '%D0%9E%D0%B1%D1%8A%D1%91%D0%BC%20%D0%B0%D0%BA%D0%B2%D0%B0%D1%80%D0%B8%D1%83%D0%BC%D0%B0%3A%20',
  // 100: 'до 200 л.',
  100: '%D0%B4%D0%BE%20200%20%D0%BB.%20',
  // 200: 'от 200 до 300 л.',
  200: '%D0%BE%D1%82%20200%20%D0%B4%D0%BE%20300%20%D0%BB.%20',
  // 300: 'от 300 до 400 л.',
  300: '%D0%BE%D1%82%20300%20%D0%B4%D0%BE%20400%20%D0%BB.%20',
  // 400: 'от 400 до 600 л.',
  400: '%D0%BE%D1%82%20400%20%D0%B4%D0%BE%20600%20%D0%BB.%20',
  // 600: 'от 600 до 800 л.',
  600: '%D0%BE%D1%82%20600%20%D0%B4%D0%BE%20800%20%D0%BB.%20',
}