import classes from './Logo.module.scss'
import {IconLogo} from 'shared/icons'

export const Logo = () => {
  const onLogoClick = () => {
    'onLogoClick'
  }

  return (
    <button
      className={classes.logo}
      type='button'
      onClick={onLogoClick}
    >
      <IconLogo />
    </button>
  )
}