import {useDispatch} from 'react-redux'
import {useCalc} from 'shared/model/hooks'
import {Select} from 'shared/ui'
import image from './assets/lamp.gif'
import classes from './CostCalc.module.scss'
import {PRICE, TYPES, VOLUMES} from 'shared/config/calc'

export const CostCalc = () => {
  const dispatch = useDispatch()
  const {type, setType, volume, setVolume, coast, setCoast} = useCalc()

  const handleFormSubmit = (event) => {
    event.preventDefault()
    dispatch(setCoast(PRICE[type][volume]))
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.body}>
        <h2 className={classes.title}>
          Cтоимость: {coast} ₽
        </h2>

        <form className={classes.form}
          onSubmit={handleFormSubmit}
        >
          <div className={classes.row}>
            <label className={classes.field}>
              <span>Тип аквариума:</span>
              <Select
                options={TYPES}
                value={type}
                onChange={(value) => dispatch(setType(value))}
              />
            </label>

            <label className={classes.field}>
              <span>Объём аквариума:</span>
              <Select
                options={VOLUMES}
                value={volume}
                onChange={(value) => dispatch(setVolume(value))}
              />
            </label>
          </div>

          <button className={classes.btn}
            type='submit'
            disabled={!(volume && type)}
          >
            Расчитать
          </button>
        </form>

        <div className={classes.image}>
          <img src={image} alt='Аквадизайнер' />
        </div>
      </div>
    </div>
  )
}