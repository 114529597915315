import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  type: '',
  volume: undefined,
  coast: 0
}

const calcSlice = createSlice({
  name: 'calc',
  initialState,
  reducers: {
    setType: (state, action) => {
      state.type = action.payload
    },
    setVolume: (state, action) => {
      state.volume = action.payload
    },
    setCoast: (state, action) => {
      state.coast = action.payload
    },
  }
})

const {reducer: calcReducer} = calcSlice
const {setType, setVolume, setCoast} = calcSlice.actions

export {
  calcReducer,
  setType,
  setVolume,
  setCoast
}